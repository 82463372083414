export const SearchRFPServiceUrlPath = '/v1/rfps';
export const GetRFPDetailsServiceUrlPath = '/v1/rfps';
export const GetProposalDetailsServiceUrlPath = '/v1/rfps';
export const MessageDetailsServiceUrlPath = '/v1/Messages';
export const LocationServiceUrlPath = '/v1/locations';
//TODO: Update the path when service available
export const MessageListSearchServiceUrlPath = '/v1/messages';
export const MessagePreviewServiceUrlPath = '/v1/messages/preview';
export const SubscriptionListServiceUrlPath = '/subscriptions/api/v1/Subscriptions';
export const UserListServiceUrlPath = '/v1/users';
export const MessageAttachmentsPath = '/v1/attachments'

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const toastTypes = { 'success': 'success', 'info': 'info', 'warning': 'warning', 'danger': 'danger', 'tip': 'tip' }
export const toastStyles = { 'big': 'big', 'small': 'small', 'widget':'widget', 'sm':'smallModal' };
export const GetBookingDataServiceUrlPath = '/v1/rfps';