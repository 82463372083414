import { Component } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from 'rxjs';
import { DfMedia } from '@design-factory/design-factory';
import { RfpProposalDetailsService } from '../../services/rfp-proposal-details.service';
import { takeUntil } from 'rxjs/operators';
import { GetProposalCommentsData, GetRfpProposalCommentsModel } from '../../models/rfp-proposal-details.model';
import { AlertPopupConfig, AlertPopupService } from 'src/app/services/alert-popup.service';
import { RFPError } from '../../constants/rfp-error.constants';


@Component({
  selector: 'app-comment-container',
  templateUrl: './comment-container.component.html',
  styleUrl: './comment-container.component.scss'
})

export class CommentContainerComponent {
  selectedData: any[];
  pageTitle: string;
  active: any = 1;
  unSubscribe$ = new Subject();
  subscriptionComments: GetProposalCommentsData;
  isCommentsLoading: boolean = false;
  isLocationCommentsLoading: boolean = false;
  locationId: string;
  subScriptionComments: any[] = [];
  locationComments: any[] = [];
  rowData: GetRfpProposalCommentsModel[] = [];
  rowDataLocation: GetRfpProposalCommentsModel[] = [];
  index: number;
  errors$ = new Subject<any>();

  constructor(private alertPopupService: AlertPopupService,
    public readonly mediaService: DfMedia,
    public activeModal: NgbActiveModal,
    private readonly rfpProposalDetailsService: RfpProposalDetailsService) { }

  async ngOnInit(): Promise<void> {
    this.selectedData = [];
    this.errors$.pipe(takeUntil(this.unSubscribe$)).subscribe(x => {
      this.alertErrorMessages(x.defaultText, 'error', false);
      this.activeModal.close();
    });

    this.getSubscriptionComments();
    this.getLocationsComments();
  }

  getSubscriptionComments() {
    this.isCommentsLoading = true;
    this.rfpProposalDetailsService.getProposalComments(this.locationId, true, "Document").then(x => {
      if (x) {
        this.subScriptionComments = x.data.comments;
        this.rowData = [...this.subScriptionComments];
        this.addRowId(this.rowData);
        this.isCommentsLoading = false;
      }
    }).catch(err => {
      this.handleRfpDetailServiceError(err);
      this.isCommentsLoading = false;
    });
  }

  getLocationsComments() {
    var category = "";
    switch (this.pageTitle) {
      case 'Event':
        category = 'ProposalEvent';
        break;
      case 'Guestroom':
        category = 'ProposalRoom';
        break;
      case 'Proposal':
        category = 'ProposalSend';
        break;
    }

    if(!category) return;

    this.isLocationCommentsLoading = true;

    this.rfpProposalDetailsService.getProposalComments(this.locationId, false, category).then(x => {
      if (x) {
        this.locationComments = x.data.comments;
        this.rowDataLocation = [...this.locationComments];
        this.addRowId(this.rowDataLocation);
        this.isLocationCommentsLoading = false;
      }
    }).catch(err => {
      this.handleRfpDetailServiceError(err);
      this.isLocationCommentsLoading = false;
    });
  }

  handleRfpDetailServiceError(err: any) {
    if (err.status === 401) {
      this.errors$.next({ translateKey: 'common-messages-txt-unauthorized', defaultText: 'User is not authorized to perform this action.' });
      return;
    }

    const errors = err?.error?.errors?.map(x => x.code);
    if (!errors || !errors.length) return;

    this.handleServiceError(errors);
  }

  handleServiceError(errors: any): void {

    errors.forEach(e => {
      switch (e) {
        case RFPError.BAD_REQUEST: this.errors$.next({ translateKey: 'preview-txt-badrequestmessage', defaultText: 'Oops, something went wrong. Please check your input.' }); break;
        case RFPError.INTERNAL_SERVER_ERROR: this.errors$.next({ translateKey: 'preview-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' }); break;
        default: this.errors$.next({ translateKey: 'preview-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' });
      }
    });
  }

  addRowId(rowData: any[]) {
    this.index = 0;
    rowData.forEach(element => {
      element.id = this.index++;
    });
  }

  saveComments() {
    this.activeModal.close(this.selectedData);
  }

  onSubscriptionCommentsSelected(data: any) {
    this.selectedData = data;
  }

  onLocationCommentsSelected(data: any) {
    this.selectedData = data;
  }

  alertErrorMessages(message, alertType, isCancelButtonVisible?: boolean) {
    return this.alertPopupService.showPopup({ data: { title: 'Error', message: message, isHeaderRequired: true, type: alertType, isCancelButtonVisible: isCancelButtonVisible, okButtonText: 'OK', cancelButtonText: 'Cancel' } } as AlertPopupConfig).then(_ => {
      this.activeModal.close();
    },
      () => {
        this.activeModal.close();
      });
  }

}
