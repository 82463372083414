import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-content-tooltip-cellrenderer',
  templateUrl: './content-tooltip-cellrenderer.component.html',
  styleUrl: './content-tooltip-cellrenderer.component.scss'
})
export class ContentTooltipCellrendererComponent implements ITooltipAngularComp {
  params: any;

  constructor() { }
  
  agInit(params: any): void {
    this.params = params
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }
}
