import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { GetRfpDetailsResponseModel, PutReassignRfpRequestEnvelope, RFPStatusChangeRequestModel, UpdateRfpRequestModel, UpdateRfpOwnerChangeRequestModel } from '../models/rfp-detail.model';
import { GetRFPDetailsServiceUrlPath, SearchRFPServiceUrlPath } from './../../../../../src/app/utils/constants';
import { RfpDetailsAdditionalInfoResponseModel } from '../models/rfp-detail-additional-info.model';
import { QuestionsAnswerResponseModel } from '../models/rfp-detail-rfp-tab.view-model';
import { RfpStatusCountResponseModel } from '../models/rfp-status-count-model';
import { formatDate } from '@angular/common';

import { ProposalProvidersResponse } from '../models/proposal-choice.model';
import { RfpStatusRevenueResponseModel } from '../models/rfp-status-revenue-model';
@Injectable({
  providedIn: 'root'
})
export class RfpDetailsService {

  private rfpServiceBaseUrl: string;
  private rfpDetailsServiceUrl: string;
  private proposalProviderServiceUrl: string;

  constructor(private http: HttpClient) {
    this.rfpServiceBaseUrl = sessionStorage.getItem('RFPServiceBaseUrl');
    this.rfpDetailsServiceUrl = `${this.rfpServiceBaseUrl}${GetRFPDetailsServiceUrlPath}`;
    this.proposalProviderServiceUrl = `${sessionStorage.getItem("RFPServiceBaseUrl")}${SearchRFPServiceUrlPath}`;
  }

  public getProposalProviders(rfpId: string,channelId: number=0): Observable<ProposalProvidersResponse> {
    if(channelId === 0){
      return this.http.get<ProposalProvidersResponse>(`${this.proposalProviderServiceUrl}/${rfpId}/proposal-providers`);
    }
    else{
      return this.http.get<ProposalProvidersResponse>(`${this.proposalProviderServiceUrl}/${rfpId}/proposal-providers?channelId=${channelId}`);
    }  
  }
  public getRFPDetails(rfpId: string): Observable<GetRfpDetailsResponseModel> {
    
    let paramString = `children=all`;
    return this.http.get<GetRfpDetailsResponseModel>(`${this.rfpDetailsServiceUrl}/${rfpId}?${paramString}`);
  }
  public getAdditionalInformationForRfp(rfpId: string): Observable<RfpDetailsAdditionalInfoResponseModel> {
    return this.http.get<RfpDetailsAdditionalInfoResponseModel>(`${this.rfpDetailsServiceUrl}/${rfpId}/additional-Information`);
  }

  public updateAnswersForQuestions(rfpId: string, response: QuestionsAnswerResponseModel) {
    return this.http.put(`${this.rfpDetailsServiceUrl}/${rfpId}/questions/answers`, response);
  }
  public updateRfpStatus(rfpId: string, response: RFPStatusChangeRequestModel) {
    return this.http.put(`${this.rfpDetailsServiceUrl}/${rfpId}/status`, response);
  }

  public reassignRfp(rfpId: string, response: PutReassignRfpRequestEnvelope) {
    return this.http.put(`${this.rfpDetailsServiceUrl}/${rfpId}/Owner`, response);

  }

  public getRFPStatusCountsForLocations(locations:string):Observable<RfpStatusCountResponseModel>{
    //Passing hard coded locale as API does not suport locale 
    var endDate =formatDate(new Date(),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    var startDate =formatDate(new Date().setDate(new Date().getDate() - 7),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    return this.http.get<RfpStatusCountResponseModel>(`${this.rfpDetailsServiceUrl}/status-counts`+ `?StartDate=`+startDate+`&EndDate=`+endDate+ `&locationIds=`+ locations);
  }

  public getRFPStatusCounts():Observable<RfpStatusCountResponseModel>{
    //Passing hard coded locale as API does not suport locale 
    var endDate =formatDate(new Date(),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    var startDate =formatDate(new Date().setDate(new Date().getDate() - 7),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    return this.http.get<RfpStatusCountResponseModel>(`${this.rfpDetailsServiceUrl}/status-counts`+ `?StartDate=`+startDate+`&EndDate=`+endDate);
  }

  public getRFPStatusRevenuesForRevWon(locations:string):Observable<RfpStatusRevenueResponseModel>{
    //Passing hard coded locale as API does not suport locale 
    var endDate =formatDate(new Date(),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    var startDate =formatDate(new Date().setDate(new Date().getDate() - 7),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    return this.http.get<RfpStatusRevenueResponseModel>(`${this.rfpDetailsServiceUrl}/status-revenues`+ `?StartDate=`+startDate+`&EndDate=`+endDate + `&statuses=awarded,selfawarded` + `&locationIds=`+ locations);
  }

  public getRFPStatusRevenuesForRevLost(locations:string):Observable<RfpStatusRevenueResponseModel>{
    //Passing hard coded locale as API does not suport locale 
    var endDate =formatDate(new Date(),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    var startDate =formatDate(new Date().setDate(new Date().getDate() - 7),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    return this.http.get<RfpStatusRevenueResponseModel>(`${this.rfpDetailsServiceUrl}/status-revenues`+ `?StartDate=`+startDate+`&EndDate=`+endDate + `&statuses=cancelled,withdrawn` + `&locationIds=`+ locations);
  }

  public getRFPStatusRevenuesForRevTD(locations:string):Observable<RfpStatusRevenueResponseModel>{
    //Passing hard coded locale as API does not suport locale 
    var endDate =formatDate(new Date(),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    var startDate =formatDate(new Date().setDate(new Date().getDate() - 7),'YYYY-MM-ddTHH:mm:ss.SSS','en-US');    
    return this.http.get<RfpStatusRevenueResponseModel>(`${this.rfpDetailsServiceUrl}/status-revenues`+ `?StartDate=`+startDate+`&EndDate=`+endDate + `&statuses=TurnedDown` + `&locationIds=`+ locations);
  }
  
  
  public updateRfpDetails(rfpId: string, response: UpdateRfpRequestModel) {
    return this.http.put<GetRfpDetailsResponseModel>(`${this.rfpDetailsServiceUrl}/${rfpId}`,response);
  }

}
