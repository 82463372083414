export const maxQtyValue = 100000;
export const maxTotalQtyValue = 400000;
export const maxRateValue = 2147483648;
export const maxNoOfEvents = 991;
export const maxCopyLimit = 76;
export const maxDeleteLimit = 76;
export const maxPayloadLimitStatusCode = 413;
export const successStatusCode = 200;
export const MAX_NO_OF_GUESTROOM_DAYS = 100;
export const MAX_GR_COPY_SERVICE_LIMIT = 15;
export const MAX_GR_DELETE_SERVICE_LIMIT = 15;