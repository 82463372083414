import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserResponseModel, UsersDetailResponseModel } from '../models/user-list';
import { UserListServiceUrlPath } from "src/app/utils/constants";

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  userServiceUrl: string;
  public users$ = new Subject<UserResponseModel>();
  public areUsersLoading$ = new Subject<boolean>();
  public errors$ = new Subject<any>();
  userResponseModel: UserResponseModel;
  paramString:string;
  public userDetails$ = new Subject<UsersDetailResponseModel>(); 
  private email = new BehaviorSubject<string>('');
  emailDetails$ = this.email.asObservable();


  constructor(private http: HttpClient) {
  }

  // Lazy initialization using a getter
  getUserServiceUrl(): string {
    if (!this.userServiceUrl) {
      this.userServiceUrl =  `${sessionStorage.getItem(
        "UserServiceBaseUrl"
      )}${UserListServiceUrlPath}`;
    }
    return this.userServiceUrl || '';  // return a default or handle null case
  }

  getUserDetails(mbuserguid: string): Observable<UsersDetailResponseModel> {
    return this.http.get<UsersDetailResponseModel>(`${this.getUserServiceUrl()}/${mbuserguid}`);
  }


  getUserDetail(mbuserguid: string): void {
    this.http.get<UsersDetailResponseModel>(`${this.getUserServiceUrl()}/${mbuserguid}`).subscribe(x => {
     this.userDetails$.next(x);
     },
     (error) => {
     }
   );
 }

  getUsersList(pageLimit: number, continuationToken: string, locationId: string, 
      sortBy: string, status: string, includeGlobalSalesUsers: boolean, role: string): void {  

    let paramString="";
    if(locationId != undefined) {
      paramString =  locationId.toString() == '' ? "" : "locationId="+locationId;
    }

    paramString += paramString == "" ? "limit=" + pageLimit  : "&limit=" + pageLimit;
    paramString += !continuationToken  ? "" : "&continuationToken=" + continuationToken;
    paramString += sortBy == "" ? "" : "&sortBy=" + sortBy;
    paramString += status == ""  ? "" : "&status=" + status;
    paramString += !includeGlobalSalesUsers ? "" : "&includeGlobalSalesUsers=" + includeGlobalSalesUsers ;
    paramString += role == "" ? "" : "&role=" + role ;
    this.areUsersLoading$.next(true);

    this.http.get<UserResponseModel>(`${this.getUserServiceUrl()}?${paramString}`)
    .subscribe(result => {
      if (result && result.error) {
        this.errors$.next(result.error);
        } else {
        this.users$.next(result);
        }
        this.areUsersLoading$.next(false);
      },
      (errorResult) => {
        this.areUsersLoading$.next(false);    
        switch (errorResult.error.errors[0].status) {
          case 400: this.errors$.next({ translateKey: 'common-messages-txt-badrequestmessage', defaultText: 'Oops, something went wrong. Please check your input.' });
              break;
          case 500: this.errors$.next({ translateKey: 'common-messages-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' });
              break;
          default: this.errors$.next({ translateKey: 'common-messages-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' });
        
        }    
      });
  }
  
}
