import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { DfMedia } from '@design-factory/design-factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, PaginationChangedEvent, ModelUpdatedEvent, GridOptions, GetRowIdParams, ColDef, ITooltipParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { LocalizedDateWithoutTimezonePipe } from 'src/app/pipes/localized-date-without-timezone.pipe';
import * as Messages from "../../../../../../src/app/components/error-page/error.constant";
import { ContentTooltipCellrendererComponent } from '../content-tooltip-cellrenderer/content-tooltip-cellrenderer.component';
import { GetRfpProposalCommentsModel } from '../../models/rfp-proposal-details.model';

@Component({
  selector: 'app-location-comments-container',
  templateUrl: './location-comments-container.component.html',
  styleUrl: './location-comments-container.component.scss'
})

export class LocationCommentsContainerComponent {
  @Output() commentsSelected = new EventEmitter<any>();
  @Input() rowData: GetRfpProposalCommentsModel[] = [];

  @ViewChild('mygrid')
  mygrid!: AgGridAngular;


  FILTER_PAG_REGEX = /[^0-9]/g;
  page = 1;
  paginationChanged = false;
  collectionSize;
  selectedValue = 10;
  active = 1;
  pageTitle: string;
  pagination = true;
  subscription: Subscription = new Subscription();
  pageNbrOfItemsGroup: UntypedFormGroup;
  tableItemsNumber = 0;
  isSmallScreen = false;
  isPaginationVisible = false;
  startRowNumberInPage: number = 1;
  endRowNumberInPage: number = 1;
  currentPageNumber: number;
  totalRecordsCount: number = 0;
  noRowsTemplate: string;
  gridApi: GridApi;
  domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';

  public perPageOptions: any = sessionStorage.getItem('PageSizeOptions')?.split(',').map(Number);
  public defaultPageSize = +sessionStorage.getItem('DefaultPageSize');

  @ViewChild('additionalInfoGrid')
  additionalInfoGrid!: AgGridAngular;
  pageSize: number;
  selectedData: any[];

  constructor(
    formBuilder: FormBuilder,
    public readonly mediaService: DfMedia,
    public activeModal: NgbActiveModal,
    private readonly localizedDateWithoutTimezonePipe: LocalizedDateWithoutTimezonePipe,
  ) {
    // Init pagination

    this.pageNbrOfItemsGroup = formBuilder.group({
      list: [this.perPageOptions[1]]
    });
    this.subscription.add(
      this.pageNbrOfItemsGroup.controls['list'].valueChanges.subscribe((value) => {
        if (this.perPageOptions !== value) {
          this.perPageOptions = value;

          // Attention Specific in case of expandable feature
          // this.closeAllExpandedNode();
          this.mygrid.api.setGridOption('paginationPageSize', this.perPageOptions);
        }
      })
    );

    this.subscription.add(
      this.mediaService.getObservable(['xs', 'sm']).subscribe((isSmallScreen) => {
        this.isSmallScreen = isSmallScreen;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const rowData = changes['rowData']?.currentValue;
    if (rowData && rowData.length) {
      // this.rowData = this.mapIdToAdditionalInformation(changes['rowData']?.currentValue);
      this.initializePagination();
    } else {
      this.isPaginationVisible = false;
    }
  }

  initializePagination() {
    this.isPaginationVisible = true;
    this.totalRecordsCount = this.rowData?.length;
    this.currentPageNumber = 1;
  }

  onItemsPerPageChange(event: Event) {
    this.pageSize = Number(event)
    this.mygrid.api.paginationGoToPage(this.page - 1);
    this.endRowNumberInPage = this.page * this.pageSize > this.totalRecordsCount ? this.totalRecordsCount : this.page * this.pageSize;
    this.startRowNumberInPage = this.page * this.pageSize - (this.pageSize - 1);
  }

  addOneIfRemainderNotZero(dividend: number, divisor: any): number {
    return Math.ceil(dividend / divisor);
  }

  async ngOnInit(): Promise<void> {
    this.selectedData = [];
    this.isPaginationVisible = true;
    this.pageSize = this.defaultPageSize;
    this.noRowsTemplate = $localize`${Messages.NO_RECORDS}`;
    await new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.rowData && this.rowData.length >= 0) {
          this.endRowNumberInPage = this.pageSize > this.rowData?.length ? this.rowData?.length : this.page * this.pageSize;
          this.startRowNumberInPage = this.page * this.pageSize - (this.pageSize - 1);
          this.totalRecordsCount = this.rowData.length
        }
      }, 100);
    });
  }

  update(ev: PaginationChangedEvent | ModelUpdatedEvent) {
    this.tableItemsNumber = ev.api.getRenderedNodes().length;
  }

  // Pagination change page. By design when we change page we close all expanded row.
  onPageChange(page: number) {
    this.page = page;
    this.endRowNumberInPage = this.page * this.pageSize > this.totalRecordsCount ? this.totalRecordsCount : this.page * this.pageSize;
    this.startRowNumberInPage = this.page * this.pageSize - (this.pageSize - 1);
    this.mygrid.api.paginationGoToPage(page - 1);
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(this.FILTER_PAG_REGEX, '');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onSelectionChanged(event: any) {
    this.selectedData = this.gridApi.getSelectedRows();

    if (this.selectedData.length === 3) {
      this.gridApi.forEachNode((node) => {
        if (!this.selectedData.includes(node.data)) {
          node.selectable = false;
        }
      });
    } else {
      this.gridApi.forEachNode((node) => {
        node.selectable = true;
      });
    }
    this.commentsSelected.emit(this.selectedData);
  }

  saveComments() {
    this.activeModal.close(this.selectedData);
  }

  clearSelection() {
    this.gridApi.deselectAll();
  }

  gridOptions: GridOptions = {
    getRowId: (params: GetRowIdParams): string => {
      return params.data.id.toString();
    },
    rowSelection: 'multiple',
    tooltipShowDelay: 0,
    suppressRowClickSelection: true
  };

  columnDefs: ColDef[] = [
    {
      headerName: '',
      field: 'rowSelect',
      checkboxSelection: true,
      suppressMovable: true,
      suppressMenu: true,
      suppressNavigable: true,
      maxWidth: 50,
      minWidth: 50,
      editable: false,
      unSortIcon: false
    },
    {
      headerName: $localize`Name`,
      field: 'name',
      width: 200, 
      unSortIcon: true,
      tooltipValueGetter: (p: ITooltipParams) =>
        p.data.name,
    },
    {
      headerName: $localize`Description`,
      field: 'description',
      width: 300, 
      unSortIcon: true,
      tooltipValueGetter: (p: ITooltipParams) =>
        p.data.description,
    },
    {
      headerName: $localize`Content`,
      field: "content",
      width: 480, 
      unSortIcon: true,
      tooltipComponent: ContentTooltipCellrendererComponent,
      tooltipValueGetter: (p: ITooltipParams) =>
        p.data.content,
    },
  ];

}
