import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class DateHelper {

  static mapNgbDateToUTCDate = (date: NgbDate) => date ? date.year + '-' + this.formatMonthAndDay(date.month) + '-' + this.formatMonthAndDay(date.day) : null;

  static formatMonthAndDay = (x: any) => Number(x) < 10 ? '0' + x : x;
  static getCurrentDate = () => {
    const date = new Date();
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
  static convertUTCDateToNgbDate(date: any) {
    let ngbDate;
    let regexForYYYYMMDD1 = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;    //YYYY-MM-DD && YYYY-M-D
    let regexForYYYYMMDD2 = /^\d{4}\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])$/;  //YYYY/MM/DD
    if(date && !(date instanceof NgbDate)){
      if(regexForYYYYMMDD1.test(date)){
        const [year, month, day] = date.split('-').map(Number);
        ngbDate = new NgbDate(year, month, day);
      }
      if(regexForYYYYMMDD2.test(date)){
        const [year, month, day] = date.split('/').map(Number);
        ngbDate = new NgbDate(year, month, day);
      }
    }
    return ngbDate;
  }
}
