export enum RfpStatus {
  New = "New",
  Awarded = "Awarded",
  SelfAwarded = "Self-Awarded",
  TurnedDown = "Turned Down",
  Updated = "Updated",
  Withdrawn = "Withdrawn",
  Redirected = "Redirected",
  Draft = "Draft",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
  Duplicate = "Duplicate"
}
