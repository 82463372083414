import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Event, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppMenuService } from './services/app-menu.service';
import { AuthenticationService } from './services/authentication.service';
import { LocaleService } from './services/locale.service';
import { UrlService } from './services/url.service';
import { SubscriptionListService } from './services/subscription-list.service';
import { HelpService } from './services/help.service';
import { AppConfigService } from './helpers/configuration/app-config.service';
import { $localize } from '@angular/localize/init';
import { forkJoin, Subscription } from 'rxjs';
import { DfMedia, DfSideNavCollapseService, DfSideNavItem, DfSideNavService } from '@design-factory/design-factory';
import { UserListService } from 'projects/user/src/app/services/user-list.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  private maxIdleSessionTime: number;
  public isLoggedIn = false;
  public isSubscriptionSet = false;
  isLoader: boolean;
  isLoading: boolean;
  isLoggingOut: boolean = false;
  isHelpDisplayed: boolean;

  //SideNav & SidNavContextHelp
  items: DfSideNavItem[];
  previousUrl: string = null;
  currentUrl: string = '';
  public showHelp = true;
  public currentPageId: string;

  public user = {
    firstName: '',
    lastName: '',
    username: atob(sessionStorage.getItem('UserName')),
  };


  config;
  navbarCollapsed = true;
  subscription: Subscription = new Subscription();
  isSmallScreen?: boolean;
  isToggled: boolean = false;


  constructor(
    public authService: AuthenticationService,
    private subListService: SubscriptionListService,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private urlService: UrlService,
    private appMenuService: AppMenuService,
    private localeService: LocaleService,
    private modalService: NgbModal,
    private helpService: HelpService,
    public readonly sideNavService: DfSideNavService,
    public readonly sideNavCollapseService: DfSideNavCollapseService,
    public readonly mediaService: DfMedia,
    private configService: AppConfigService,
    private readonly userListService: UserListService
  ) {
    this.subscription.add(
      sideNavService.activeItem$.subscribe((item) => {
        if (item && item.value !== '') {
          // Do you action here
        }
      })
    );

    this.config = this.configService.getConfig();
    let appConfigValues = JSON.parse(this.config.applicationConfigurations);

    // Get/set the max idle session duration in seconds from config
    this.maxIdleSessionTime = appConfigValues['maxIdleSessionTime'];

    //Set the help-context page-id for the current page
    if (this.showHelp) {
      this.helpService.helpTopic$.subscribe(helpTopic => {
        this.currentPageId = helpTopic;
      });
    };

    this.router.events.subscribe(async (event: Event | RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.isLoading = false;
          this.currentUrl = (event as RouterEvent).url;
          this.urlService.setPreviousUrl(this.currentUrl);
          this.modalService.dismissAll();
          break;
        }
        case event instanceof NavigationCancel: {
          this.isLoading = false;
          break;
        }
        case event instanceof NavigationError: {
          this.isLoading = false;
          break;
        }
      }
    });

    this.localeService.setTimezone(sessionStorage.getItem(this.localeService.timeZoneKeyName));

    this.authService.isLoggedIn.subscribe(x => {
      if (x) {
        this.isLoggedIn = true;
      }
    });

    this.subListService.isSubscriptionSet$.subscribe(x => {
      if (x) {
        this.isSubscriptionSet = true;
      }
    });

    this.appMenuService.menus.subscribe(x => {
      this.sideNavService.initItems(x, false);
      this.sideNavService.showSideNav();
      this.sideNavService.setInMenuSideNav(false);
      this.sideNavCollapseService.setAnimation(false);
    });
  }

  //this method is used to toggle the side nav on click of anywhere other than sidenav and hamburger button
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const hamburger = document.getElementById('hamburger-button');
    if(hamburger && hamburger.contains(event.target as Node))
      return;
    const sidenav = document.getElementById('df-sidenav');
    if (sidenav && sidenav.contains(event.target as Node))
      return;

    this.sideNavService.hideSideNav(); // Hide the sidenav
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event | RouterEvent) => {
      this.currentUrl = (event as RouterEvent).url;
      if (this.currentUrl === "/") {
        window.location.reload();
      }
    });


    // set the max idle session duration
    this.bnIdle.startWatching(this.maxIdleSessionTime).subscribe((isTimedOut: Boolean) => {
      if (this.isLoggedIn && isTimedOut) {
        alert( $localize `Session Expired.`);
        this.isLoggingOut = true;
        this.authService.logout();
      }
    })

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.endsWith('login') && this.isLoggedIn && this.isSubscriptionSet) {
          this.isLoggedIn = false;
          this.isSubscriptionSet = false;
          this.authService.logout();
          document.querySelector("body").style.backgroundColor = "#f2f2f2";
        }
      }
    });
  
    this.user.firstName =   atob(sessionStorage.getItem('mbName')).split(' ')[0];
    this.user.lastName =  atob(sessionStorage.getItem('mbName')).split(' ')[1].toLocaleUpperCase();
  }

  setHelpVisibility(data:boolean) {
    this.isHelpDisplayed = data;
  }
}