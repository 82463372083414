import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { PaginatorConfig } from 'projects/shared/pagination/src/app/models/pagination.model';
import { Subject } from 'rxjs';
import { Constants } from '../helpers/constants/constants';
import { PageConfigSessionData } from '../models/pageconfig-sessiondata.model';

export interface userSelectionData {
  filterData?: Map<string, string>,
  pageNumber?: number,
  pageSize?: number
}

export interface updateNotificationData {
  isUpdateOrCreate?: boolean,
  routeDataMessage?: SafeHtml
}

export interface continuationTokenList {
  tableName: string,
  pagenumber: number,
  continuationToken: string
}

@Injectable({
  providedIn: "root",
})
export class SharedService {
  applicationLanguage$ = new Subject<string>();
  continuationTokenList = new Array<continuationTokenList>();

  constructor() {}

  setContinuationToken(_pageNumber: number, _continuationToken: string, tableName: string, updateExistingToken: boolean = false) {
    if (_continuationToken) {
      tableName = this.getPaginationName(tableName);

      let tokenData: continuationTokenList = {
        tableName: tableName,
        pagenumber: _pageNumber,
        continuationToken: btoa(_continuationToken),
      };

      if (!updateExistingToken) {
        this.continuationTokenList.push(tokenData);
      } else {
        // update existing token
        let existingToken = this.continuationTokenList.find(
          (x) => x.pagenumber == _pageNumber && x.tableName == tableName
        );

        if (existingToken != null) {
          existingToken.continuationToken = btoa(_continuationToken);
        } else {
          this.continuationTokenList.push(tokenData);
        }
      }
    }
  }

  getContinuationToken(_pageNumber: number, tableName: string) {

    let tokenData = this.continuationTokenList.find(x => x.pagenumber == _pageNumber && x.tableName == this.getPaginationName(tableName));
    if(tokenData != null){
      return atob(tokenData.continuationToken);
    }

    return null;
  }

  clearAllContinuationTokens(tableName: string) {
    this.continuationTokenList = this.continuationTokenList.filter(
      (x) => x.tableName != this.getPaginationName(tableName)
    ); // this filter will remove all the tokens for specific table
  }

  getPaginationName(tableName: string) {
    return `${Constants.PAGINATIONTOKEN}-${tableName}`;
  }

  setPaginationSession(tableName: string, pageSize: number, continuationToken: string, filterData : string = '', isOnlyFilterData: boolean = false) {
    this.clearPaginationSession(tableName);

    sessionStorage.setItem(`${tableName}-PageSize`, pageSize.toString());
    sessionStorage.setItem(`${tableName}-ContinuationToken`, continuationToken);

    if(isOnlyFilterData)
    {
      sessionStorage.setItem(`${tableName}-FilterData`, filterData);
    }
  }

  setPaginatorConfigSession(tableName: string, paginatorConfig: PaginatorConfig) {
    this.clearPaginatorConfig(tableName);

    sessionStorage.setItem(`${tableName}-PaginatorConfig`, JSON.stringify(paginatorConfig));
  }

  setcolumnDefsSession(tableName: string, columnDefs: any[]) {
    this.clearColumnDefs(tableName);

    sessionStorage.setItem(`${tableName}-ColumnDefs`, JSON.stringify(columnDefs));
  }

  getcolumnDefsSession(tableName: string): string {
    return sessionStorage.getItem(`${tableName}-ColumnDefs`);
  }

  getPaginatorConfigSession(tableName: string): PaginatorConfig {
    return JSON.parse(sessionStorage.getItem(`${tableName}-PaginatorConfig`));
  }

  getPaginationSession(tableName: string, isOnlyFilterData: boolean = false): PageConfigSessionData {
    var pageConfigSessionData: PageConfigSessionData = { 
                 pageSize : parseInt(sessionStorage.getItem(`${tableName}-PageSize`)),
                 continuationToken : sessionStorage.getItem(`${tableName}-ContinuationToken`),
                 filterData : isOnlyFilterData? sessionStorage.getItem(`${tableName}-FilterData`):''
                };

    return pageConfigSessionData;
  }

  clearPaginationSession(tableName: string, isOnlyFilterData: boolean = false) {
    sessionStorage.removeItem(`${tableName}-PageSize`);
    sessionStorage.removeItem(`${tableName}-ContinuationToken`);

    if(isOnlyFilterData)
    {
      sessionStorage.removeItem(`${tableName}-FilterData`);
    }
  }

  clearPaginatorConfig(tableName: string) {
      sessionStorage.removeItem(`${tableName}-PaginatorConfig`);
  }

  clearColumnDefs(tableName: string) {
    sessionStorage.removeItem(`${tableName}-ColumnDefs`);
  }

  setBackButtonClickedState(tableName: string, isClicked: string) {
    sessionStorage.setItem(`${tableName}-isBackButtonClicked`, isClicked);
  }

  getBackButtonClickedState(tableName: string): string {
    return sessionStorage.getItem(`${tableName}-isBackButtonClicked`);
  }

  setFilterEnabledState(tableName: string, isFilterEnabled: string) {
    sessionStorage.setItem(`${tableName}-isFilterEnabled`, isFilterEnabled);
  }

  getFilterEnabledState(tableName: string): string {
    return sessionStorage.getItem(`${tableName}-isFilterEnabled`);
  }

  isSearchedTermUsedState(tableName: string, isSearchedTerm: string) {
    sessionStorage.setItem(`${tableName}-isSearchedTerm`, isSearchedTerm);
  }

  getUsedSearchedTermStatus(tableName: string): string {
   return sessionStorage.getItem(`${tableName}-isSearchedTerm`);
  }
  setPaginatorButtonClickedState(tableName: string, paginatorButtonClickedState: string) {
    sessionStorage.setItem(`${tableName}-paginatorButtonClickedState`, paginatorButtonClickedState);
  }

  getPaginatorButtonClickedState(tableName: string): string {
    return sessionStorage.getItem(`${tableName}-paginatorButtonClickedState`);
  }
}