import { Directive, ElementRef, Renderer2, HostListener, AfterViewInit, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[appTextOverflowTooltip]'
})
export class TextOverflowTooltipDirective implements AfterViewInit {
  @Input() maxWidth: number;
  @Input() tooltipInstance: NgbTooltip;
  domElement: any;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.renderer.setProperty(this.domElement, 'scrollTop', 1);
    //this.setToolTip();
    this.maxWidth = this.maxWidth;
    this.domElement = this.elementRef.nativeElement;

      const elipsisCssStyles = {
             'text-overflow': 'ellipsis',
             'overflow': 'hidden',
             'white-space': 'nowrap'
            };
      Object.keys(elipsisCssStyles).forEach(element => {
           this.renderer.setStyle(
           this.domElement, `${element}`, elipsisCssStyles[element]
           );
        });
  }


// This will be used if the tooltip needs to be shown only if the text is overflowing and not always
// @HostListener('window:mouseover', ['$event.target'])
//   setToolTip() {
//     if(!this.domElement) return;

//     if(this.domElement.offsetWidth < this.domElement.scrollWidth) {
//         this.tooltipInstance.disableTooltip = false;
//       } else {
//         this.tooltipInstance.disableTooltip = true;
//       }
//    }

}