import { HttpClient } from '@angular/common/http';
import { Component, NgModule} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageDetailsService } from 'projects/message/src/app/services/message-details.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'src/app/models/create-message.model';
 
@Component({
  selector: 'app-download-attachment',
  templateUrl: './download-attachment.component.html',
  styleUrl: './download-attachment.component.scss'
})
 
export class DownloadAttachmentComponent {
  private messageId: string;
  private attachmentId: string;
  private subscription: Subscription;
 
  message: Message ;
  unSubscribe$ = new Subject();
 
 
  constructor(private route: ActivatedRoute,private router: Router, private httpClient: HttpClient, 
    private messageDetailsService: MessageDetailsService
  ) {
   }
 
  ngOnInit() {
    this.subscription = this.route.queryParams.pipe(takeUntil(this.unSubscribe$)).subscribe((params: Params) => {
      this.messageId = params['messageId'];
      this.attachmentId = params['attachmentId'];
      });
    if(this.messageId !== "" && this.attachmentId !== ""){
    this.getAttachmentDetails();
   }
     else{
       this.router.navigate(["/error"]);
     }
  }
 
  getAttachmentDetails(){
    try{
      this.messageDetailsService.getAttachmentDetails(this.messageId,this.attachmentId).toPromise().then((response) => {
        if (response != null) {
          let attachmentname = response.data.name;
          let url= response.data.url;
          this.downloadAttachment(attachmentname,url);
        }
        else{
          this.router.navigate(["/error"]);
        }
      }  ,
      error => { 
        this.router.navigate(["/error"]);
      }
      );

    }catch (e) { 
      this.router.navigate(["/error"]);
  }   
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
 
  downloadAttachment(filename: string ,url :string): void {
    this.httpClient.get(url,{ responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
                downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
        this.router.navigate(['home']);
 }
}
 