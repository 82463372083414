import { Injectable } from '@angular/core';
import { AppConfigService } from './configuration/app-config.service';
import { LocaleService } from '../services/locale.service';
import { LocalStorageConfigService } from '../services/local-storage-config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigManagerService {
  userInfo: any;
  constructor(private localStorageConfigService:LocalStorageConfigService,
    private appConfigService: AppConfigService,
    private localeService: LocaleService) { }

  getSecrets(): any {   
    let config = this.appConfigService.getConfig();
    let secretValue = JSON.parse(config.applicationConfigurations);
      //sessionStorage.setItem('ApplicationInsightKey', secretValue["ApplicationInsights-InstrumentationKey"]);
      sessionStorage.setItem('RFPServiceBaseUrl', secretValue['RFPServiceBaseUrl']);
      sessionStorage.setItem('RFPProposalServiceBaseUrl', secretValue['RFPProposalServiceBaseUrl']);
      localStorage.setItem(this.localStorageConfigService.localStorageLanguageKeyName, secretValue['ApplicationLanguage']);
      sessionStorage.setItem('PageSizeOptions', secretValue['PageSizeOptions']);
      sessionStorage.setItem(this.localeService.localeKeyName, secretValue['ApplicationLocale']);
      sessionStorage.setItem(this.localeService.timeZoneKeyName, Intl.DateTimeFormat().resolvedOptions().timeZone);
      sessionStorage.setItem('DefaultPageSize', secretValue['DefaultPageSize']);
      sessionStorage.setItem('MaxDateSpanForSearch', secretValue['MaxDateSpanForSearch']);
      //enable once service endpoint is ready
      sessionStorage.setItem('MessageServiceBaseUrl', secretValue['MessageServiceBaseUrl']); 
      sessionStorage.setItem('MeetingBrokerBaseUrl', secretValue['MeetingBrokerBaseUrl']);
      sessionStorage.setItem('UserServiceBaseUrl', secretValue['UserServiceBaseUrl']);
      
      sessionStorage.setItem('LocationServiceBaseUrl', secretValue['LocationServiceBaseUrl']);

      //TODO: If Need to set locale from secret server then we need to call it. 
      //this.localeService.setLocale(secretValue['ApplicationLocale']);
      this.localeService.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      sessionStorage.setItem('RFPBookingDataBaseUrl', secretValue['RFPBookingDataBaseUrl']);
      sessionStorage.setItem('SearchSubscriptionLimit', secretValue['SearchSubscriptionLimit']);      
      return secretValue;
  }
}
