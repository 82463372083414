<app-spinner id="responder" *ngIf="isMessagePosting"></app-spinner>
<div class="row header-bar-container" (window:resize)="onWindowResize($event)">
  <nav id="header-nav-bar">
    <!-- Back Button -->
    <button class="back-button" id="btnBack" (click)="backClicked()" placement="bottom" [ngbTooltip]='specialHeaderInfo.backButtonTooltipText'>
      <span class="fa-light fa-chevron-left" style="font-size: large"></span> <a i18n>Back</a>
    </button>

    <div class="vertical-separator" style="margin-left: 0px;"></div>

    <!--Left  Details Section -->
    <section class="details-section">
      <h2 id="header-title-text" #titleELement (mouseover)=checkifOverflowingText(titleELement)
      [ngbTooltip]="showTooltip ? specialHeaderInfo.title : null" 
      class="align-middle title-text">
    {{ specialHeaderInfo.title }}
  </h2>

        <div class="status-badge" appRfpStatusBadge>{{ specialHeaderInfo.status }}</div>

        <div *ngFor="let item of headerDataLeft" [style]="item.divStyle" [class]="item.iconCssClass" #ele
        [ngbTooltip]="item.toolTipText" (mouseover)="onMouseOver(ele, item.id)" appTextOverflowTooltip>
          <span [class]="item.spanCssClass" *ngIf="item?.isDisplayId">{{item.id}}: </span>
          <span [class]="item.spanCssClass" *ngIf="!item?.isDisplayId"></span>
          <span [class]="item.valueSpanCssClass" [style]="item.valueSpanStyle">{{item.value}}</span>
        </div>
    </section>
    <!--Right  Details Section -->
    <div class="right-header-data-group">
      <span *ngFor="let item of headerDataRight" [style]="item.divStyle" #tooltip="ngbTooltip" [id]="item.elementId"
        [ngbTooltip]="item.toolTipText" appTextOverflowTooltip>
        <span>{{ item.id }}:</span>
        <span [style]="item.valueSpanStyle"> {{ item.value }} </span>
      </span>
    </div>


    <div *ngIf="specialHeaderInfo.messageFlow != 'Proposal'; else spacer" class="vertical-separator"></div>
    <ng-template #spacer>
      <div class="spacer"></div>
    </ng-template>

    <!-- Dynamic Actions -->
    <div  class="action-container" *ngIf="headerActions?.length">
        <!-- Dynamic Actions  Dropdown-->
         <div *ngIf="specialHeaderInfo.messageFlow === 'Proposal'">
      <div ngbDropdown class="d-inline-block" *ngFor="let action of headerActions  | filterDropdownActions: false | filterButton: true">
        <button type="button" [class]="action.buttonCssClass" ngbDropdownToggle id="{{action.buttonId}}"
            [style]="action.buttonStyle" [disabled]="!action.enable" aria-hidden="true">
            {{ action.displayText }}
          </button>
          <div ngbDropdownMenu aria-labelledby="ngbDropdown" id="actionDropdown">
            <button *ngFor="let option of action.dropdownOptions; let id = index" type="button" ngbDropdownItem
              class="dropdown-item" [class.active]="id === dropDownOptionSelected[1]" [disabled]="option.label === 'Disabled'"
              (click)="openPreviewModal(id)" id="{{option.buttonId}}">
              <span [class]="option.icon"></span>
              {{ option.label }}
            </button>
          </div>
          </div>
        </div>
           <!-- Dynamic Actions   Button-->
      <button *ngFor="let action of headerActions  | filterDropdownActions: false | filterButton: false" [class]="action.buttonCssClass"
        [style]="action.buttonStyle" [disabled]="!action.enable" (click)="actionClicked(action.id)" id="{{action.buttonId}}">
        <span [class]="action.iconCssClass"></span>
        <span> {{ action.displayText }}</span>
      </button>

    </div>

    <!--Actions Dropdown -->
    <div *ngIf="isAnyDropdownEnabled()" ngbDropdown class="align-middle">
      <button type="button" class="btn action-dropdown-button" ngbDropdownToggle id="aiActions">
        <span class="fa-light fa-ellipsis-vertical" style="color: black; font-size: larger"></span>
      </button>
      <div ngbDropdownMenu aria-labelledby="nav1-dropdown2-withdropdown" class="dropdown-menu-right"
        id="actionsDropdown">
        <button ngbDropdownItem *ngFor="let action of headerActions | filterDropdownActions: true" [disabled]="!action.enable"
          (click)="actionClicked(action.id)" id="rfp-action-{{action.id}}" style="padding: 5px">
          <span class="fa-light" [class]="action.iconCssClass" style="margin: 0 5px"></span>
          {{ action.displayText }}</button>
      </div>
    </div>
  </nav>
</div>