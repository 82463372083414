import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfigService } from './app/helpers/configuration/app-config.service';
import 'froala-editor/js/plugins.pkgd.min.js';
import { AppModule } from './app/modules/app.module';
import { environment } from './environments/environment';
import { LocaleService } from './app/services/locale.service';

if (environment.production) {
  enableProdMode();
}


if (localStorage.getItem("ApplicationLocale") === null) {
  localStorage.setItem("ApplicationLocale", "en");
}

AppConfigService.loadConfig().then(() => {
  LocaleService.setLocale(localStorage.getItem("ApplicationLocale")).then(() => {
    return platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
});
