import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { RfpStatus } from 'projects/rfp-list/src/app/enums/rfp-status.enum';

@Directive({
    selector: '[appRfpStatusBadge]'
})
export class RfpStatusBadgeDirective implements AfterViewInit {
  greenTextColor = '#008540';
  greenBgColor = '#e6f3ec';
 
  redTextColor = '#c60000';
  redBgColor = '#fae6e6';
 
  blueTextColor = '#005eb8';
  blueBgColor = '#e6eff8';
 
  purpleTextColor = '#6f2b8d';
  purpleBgColor = '#f0e9f3';
 
  blackTextColor = '#1a1a1a';
  greyBgColor = '#e6e6e6';

  yellowTextColor = '#ca8007';
  yellowBgColor = '#fef6e9';

  statusColor = {
    [RfpStatus.New]: { bgColor: this.greenBgColor, color: this.greenTextColor },
    [RfpStatus.Awarded]: { bgColor: this.blueBgColor, color: this.blueTextColor },
    [RfpStatus.Updated]: { bgColor: this.purpleBgColor, color: this.purpleTextColor },
    [RfpStatus.SelfAwarded]: { bgColor: this.yellowBgColor, color: this.yellowTextColor },
    [RfpStatus.TurnedDown]: { bgColor: this.greyBgColor, color: this.blackTextColor },  
    [RfpStatus.Withdrawn]: { bgColor: this.greyBgColor, color: this.blackTextColor },
    [RfpStatus.Cancelled]: { bgColor: this.redBgColor, color: this.redTextColor },
    // below status needs to be updated
    [RfpStatus.Draft]: { bgColor: this.greenBgColor, color: this.greenTextColor }, // updated same as new 
    [RfpStatus.Deleted]: { bgColor: this.greyBgColor }, 
    [RfpStatus.Redirected]: { bgColor: this.yellowBgColor, color: this.yellowTextColor },
    [RfpStatus.Duplicate]: { bgColor: this.yellowBgColor, color: this.yellowTextColor }
  };
  domElement: any;

  // Mapping for statuses with spaces
  statusMapping = {
    'Turned Down': RfpStatus.TurnedDown,
    'Self Awarded': RfpStatus.SelfAwarded
  };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.applyStatusColor();
  }

  applyStatusColor() {
    this.domElement = this.elementRef.nativeElement;
    let statusText = this.domElement.innerText.trim();

    // Normalize status text if it exists in the mapping
    if (this.statusMapping[statusText]) {
      statusText = this.statusMapping[statusText];
    }
    const statusConfig = this.statusColor[statusText];

    if (statusConfig) {
      this.domElement.style.color = statusConfig.color;
      this.domElement.style.backgroundColor = statusConfig.bgColor;
    }
    this.domElement.style.fontSize = '12px';
    this.renderer.addClass(this.domElement, 'badge');
  }

}