import { Pipe, PipeTransform } from '@angular/core';
import { SubHeaderActions } from '../models/subheader-actions.model';

@Pipe({
  name: 'filterButton',
})
export class FilterButtonPipe implements PipeTransform {

  transform(items: SubHeaderActions[], isDropdown: boolean) : SubHeaderActions[] {
    if (!items) return [];

    return items.filter(item => item.isDropdown === isDropdown);
  }

}