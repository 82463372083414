<form [formGroup]="pageNbrOfItemsGroup" novalidate>
    <ng-template #myItemsPerPage>
        <div class="form-group ms-auto d-flex flex-nowrap df-pagination-secondary-elements">
            <label for="table-pagination-numberListFC" class="d-flex align-items-center text-nowrap" i18n>Items per
                page</label>
            <ng-select id="table-pagination-numberList" class="ms-3" labelForId="table-pagination-numberListFC"
                [selectOnTab]="true" name="numberListFC" [items]="perPageOptions" formControlName="list"
                [searchable]="false" (ngModelChange)="onItemsPerPageChange($event)" [clearable]="false"></ng-select>
        </div>
    </ng-template>

    <div class="d-flex flex-column align-items-stretch">
        <div class="comment-selection" *ngIf="selectedData.length > 0">
            <p i18n>
                {{selectedData.length}} of 3 comments selected.
                <button class="btn btn-comment-clear-icon"><span class="fa-times fa-solid"
                        (click)="clearSelection()"></span></button>
                <a class="df-link-more df-link-more-sm btn-comment-clear-link" [routerLink]=""
                    (click)="clearSelection()">
                    <span class="df-link-more-text btn-comment-clear-text">Clear
                        Selection</span>
                </a>
            </p>
        </div>
        <ag-grid-angular #mygrid id="df-myGrid-pagination"
            class="df-table ag-theme-alpine grid-media comment-grid-container" [rowData]="rowData"  [tooltipMouseTrack]="true"
            [columnDefs]="columnDefs" [gridOptions]="gridOptions" [paginationPageSize]="defaultPageSize"
            [pagination]="pagination" [suppressPaginationPanel]="true" (gridReady)="onGridReady($event)"
            (modelUpdated)="update($event)" (paginationChanged)="update($event)"
            (selectionChanged)="onSelectionChanged($event)">
        </ag-grid-angular>
        <div class="d-flex justify-content-between my-5 w-100" *ngIf="isPaginationVisible">
            <span class="d-flex align-items-center text-nowrap df-pagination-secondary-elements" id="paginationTotal"
                i18n>
                {{startRowNumberInPage}} - {{endRowNumberInPage}} of {{totalRecordsCount}} items</span>

            <div [class.start-0]="!isSmallScreen" [class.position-absolute]="!isSmallScreen"
                [class.end-0]="!isSmallScreen" class="d-flex justify-content-center">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="rowData.length" [page]="page"
                    aria-label="Default pagination" (pageChange)="onPageChange($event)" [boundaryLinks]="!isSmallScreen"
                    [class.with-boundary-links]="!isSmallScreen" class="df-custom-pages" size="sm">

                    <ng-template ngbPaginationFirst><span
                            [ngbTooltip]="'addInfo-pagination-txt-firstpage'|translate: 'First Page'"
                            tooltipClass="boundarylinks-tooltip">First Page</span></ng-template>
                    <ng-template ngbPaginationPrevious><span
                            [ngbTooltip]="'addInfo-pagination-txt-previouspage'|translate: 'Previous Page'"
                            tooltipClass="boundarylinks-tooltip">Previous Page</span></ng-template>
                    <ng-template ngbPaginationNext><span
                            [ngbTooltip]="'addInfo-pagination-txt-nextpage'|translate: 'Next Page'"
                            tooltipClass="boundarylinks-tooltip">Next Page</span></ng-template>
                    <ng-template ngbPaginationLast><span
                            [ngbTooltip]="'addInfo-pagination-txt-lastpage'|translate: 'Last Page'"
                            tooltipClass="boundarylinks-tooltip">Last Page</span></ng-template>

                    <ng-template ngbPaginationPages let-page let-pages="pages">
                        @if (pages.length >= 0) {
                        <li class="ngb-custom-pages-item">
                            <div class="d-flex flex-nowrap px-2">
                                <label id="table-pagination-paginationInputLabel" for="table-pagination-paginationInput"
                                    class="col-form-label me-4 ms-1" i18n>Page</label>
                                <input #i type="text" inputmode="numeric" pattern="[0-9]*"
                                    class="form-control df-custom-pages-input px-2 text-center"
                                    id="table-pagination-paginationInput" [value]="page"
                                    (keyup.enter)="onPageChange(+i.value)" (blur)="onPageChange(+i.value)"
                                    (input)="formatInput($any($event).target)"
                                    aria-labelledby="table-pagination-paginationInputLabel table-pagination-paginationDescription"
                                    size="2" />
                                <span id="table-pagination-paginationDescription"
                                    class="col-form-label text-nowrap px-4">
                                    of {{ pages.length }}</span>
                            </div>
                        </li>
                        }
                    </ng-template>
                </ngb-pagination>
            </div>
            @if (!isSmallScreen) {
            <ng-container [ngTemplateOutlet]="myItemsPerPage"></ng-container>
            }
        </div>
        @if (isSmallScreen) {
        <ng-container [ngTemplateOutlet]="myItemsPerPage"></ng-container>
        }
    </div>
</form>