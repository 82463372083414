  <div class="input-group dtPickerWrappper" [dfCloseInputDatePicker]="dp">
        <input
        id="datePicker"
        class="dtPicker"
        datepickerClass="ag-custom-component-popup"
        #dpin
        [(placeholder)]="placeHolder"
        aria-label="Date"
        name="dp"
        container="body"
        [(ngModel)]="inputDate"
        ngbDatepicker
        #dp="ngbDatepicker"
        [autoClose]="false"
        [displayMonths]="1"
        [navigation]="'arrows'"
        [outsideDays]="'collapsed'"
        [showWeekNumbers]="false"
        [dayTemplate]="dpdt"
        (dateSelect)="dpin.focus()"
        [dfInputIcon]="['append']"
        [footerTemplate]="footertemplate"
        [minDate]="minDateValue"
        [maxDate]="maxDateValue"
      />
      <div class="input-group-append">
        <button class="input-group-text fa-light fa-calendar" (click)="dp.toggle();" type="button">
          <span class="visually-hidden">Toggle datepicker</span>
        </button>
      </div>
    </div>
      <ng-template #footertemplate>
        <div class="text-center">
            <button class="btn" id="clearDateBtn" (click)="clearDate(dpin)" i18n>
                Clear
            </button>
        </div>
    </ng-template>
    <ng-template #dpdt let-date let-disabled="disabled" let-focused="focused" let-selected="selected">
        <div
          class="df-datepicker-date"
          [class.df-datepicker-date-disabled]="disabled"
          [class.df-datepicker-date-focused]="focused"
          [class.df-datepicker-date-selected]="selected"
          role="link">
          {{ date.day }}
        </div>
      </ng-template>