export const MAX_NO_OF_GUESTROOM_DAYS: number = 100;
export const MAX_NO_OF_EVENTS:number = 991;
export const MAX_DAY_DELETE_LIMIT:number = 76;
export const MAX_DAY_COPY_LIMIT:number= 76;
export const MAX_ROOM_SIZE = 654560975;
export const MAX_RATE : string = '2147483646';
export const MAX_RATE_INT : number = 2147483646;
export const MAX_ATTENDEES = 2147483646;
export const MAX_BUDGETEDRATE = 2147483646;
export const MAX_ROOMTYPE_VALUE = 2147483646;
export const MAX_GUESTROOM_COPY_SERVICE_LIMIT = 15;
export const MAX_GUESTROOM_DELETE_SERVICE_LIMIT = 15;
export const MAX_COMMENT_CHARACTER_LIMIT = 100000;