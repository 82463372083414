import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { catchError, map, filter, take, switchMap } from "rxjs/operators";
import { AppinsightsService } from "../services/appinsights.service";
import { AuthenticationService } from "../services/authentication.service";
import { ERRORCODESTOLOG } from "../models/error";
import { User } from "oidc-client-ts";

export const retryCount = 3;

@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
    isRefreshing: boolean;
    refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private authService: AuthenticationService, private appInsightService: AppinsightsService ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (JSON.parse(sessionStorage.getItem('retry')) != false) {
                        sessionStorage.setItem('retry', 'true');
                        return this.handle401Error(request, next);
                    }
                    alert('Unauthorized');
                }
                else if (ERRORCODESTOLOG.find(x => x === error.status)) {
                    if (error.status == 403 && error.error.error) {
                        if(!request.url.endsWith('/token'))
                            alert(error.error.error);
                    } else if (error.status === 410) {
                        alert('Resource does not exist or has been deleted.')
                    }
                }
                else {
                    if(!request.url.includes('proposal-providers?channelId')) {
                        this.authService.redirectToErrorPage();
                    }
                }
                return throwError(error);
            })
        ) as Observable<HttpEvent<any>>;
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshing) {
            this.isRefreshing = true;
            // this.refreshTokenSubject.next(null);
            // this.refreshTokenSubject.next(sessionStorage.getItem('currentUserToken'));

            return this.authService.renewToken().pipe(
                switchMap((user: User) => {
                    this.isRefreshing = false; 
                    this.authService.setCurrentUser(user);               
                    this.refreshTokenSubject.next(user.profile.legacy_token as string);
                    return next.handle(this.addToken(request, user.profile.legacy_token as string)).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                sessionStorage.setItem('retry', null);
                            }
                            return event;
                        }));

                }),catchError((err, caught) => {
                    this.isRefreshing = false;
                    if(err.error === 'login_required'){
                        alert($localize `Maximum session duration reached. Please login again.`);
                        this.authService.logout();
                    }
                    else{                        
                        return EMPTY;
                    }
                })

            );

        }
        else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(accessToken => {
                    return next.handle(this.addToken(request, accessToken.toString())).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                sessionStorage.setItem('retry', null);
                            } 
                            return event;
                        }));
                })
            );
        }

    }
}