import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IRichTextOptions } from './rich-text-options';
import FroalaEditor from 'froala-editor';

/* TODO: move to config once there is a safe way to add */
const FROLA_LICENSE_KEY: string =
  'zEG4iB3B9D6B5A5D4A3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3G3A1B8A5D4F4C4==';

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent implements OnInit {

  @Output() richTextEditorChangeEvent = new EventEmitter<string>();
  @Input() currentValue: string

  public value: string;
  public isDisabled = false;
  public toolBarButtons: any;
  public options: IRichTextOptions;

  constructor() { }

  ngOnInit(): void {
    this.value = this.currentValue;
    this.toolBarButtons = this.getToolbarButtons();
    this.options = this.getInitialOptions();
    this.richTextEditorChangeEvent.emit(this.value);
  }

  private getInitialOptions(): IRichTextOptions {
    const options: IRichTextOptions = {
      imagePaste: false,
      imageUpload: false,
      fileUpload: false,
      height: 150,
      language: 'en_us',
      attribution: false,
      key: FROLA_LICENSE_KEY,
      charCounterCount: false,
      enter: FroalaEditor.ENTER_DIV,
      pastePlain: true,
      tableCellStyles: {
        'no-border': 'No border',
        'bg-gray': 'Gray',
        'border-btm': 'Bottom border',
      },
      tableStyles: {
        'border-btm': 'Rows with btm border',
        spaced: 'Spaced',
      },
      toolbarButtons: this.toolBarButtons,
      quickInsertTags: [],
      fontFamily: this.getFontFamily(),
      paragraphFormatSelection: false,
      paragraphFormat: this.getParagraphFormat(),
      placeholderText: $localize`Start typing something...`
    }
    return options;
  }

  private getParagraphFormat() {
    const paragraphFormat = {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    };
    return paragraphFormat;
  }

  private getFontFamily() {
    const fontFamily = {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Courier New, Courier, monospace': 'Courier New',
      'Garamond': 'Garamond',
      'Georgia,serif': 'Georgia',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
      'Impact,Charcoal,sans-serif': 'Impact',
    };
    return fontFamily;
  }

  private getToolbarButtons(): any {
    const toolbarButtons = {
      moreParagraph: {
        buttons: [
          'paragraphFormat',
          'paragraphStyle',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'lineHeight',
          'outdent',
          'indent',
          'formatOLSimple',
          'formatUL',
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
        ],
        align: 'left',
        buttonsVisible: 9,
      },
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'clearFormatting',
        ],
        align: 'left',
        buttonsVisible: 7,
      },
      moreRich: {
        buttons: [
          'insertTable',
          'insertHR',
          'specialCharacters',
          'insertLink',
          'html',
        ],
        align: 'left',
        buttonsVisible: 2,
      },
      moreMisc: {
        buttons: [
          'selectAll',
          'print',
          'getPDF',
          'undo',
          'redo',
          'fullscreen',
          'help',
        ],
        align: 'right',
        buttonsVisible: 6,
      },
    };
    return toolbarButtons;
  }

  public onChange(): void {
    this.richTextEditorChangeEvent.emit(this.value);
  }
}
