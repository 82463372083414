<app-spinner id="responder" *ngIf="isMessagePosting"></app-spinner>
<div class="create-message-root">
    <div class="row create-message-sticky-header">
        <div>
            <app-alert-toast [message]="message" [type]="alertType"></app-alert-toast>
        </div>
        <div class="col-12 banner-container">
            <div *ngFor="let alert of alerts">
                <ngb-alert id="createMessageAlertMessageBox" *ngIf="alert.displayAlert" dfInsertAlertIcon
                    [type]="alert.type" (closed)="alert.displayAlert = true" (closed)="closeAlert(alert)"
                    class="bannerAlert">{{ alert.message }}</ngb-alert>
            </div>
            <ngb-alert dfInsertAlertIcon id="alertToast" [class]="alertStyle" *ngIf="displayFailureAlert"
                [type]="alertType" (closed)="errorMessage=undefined" (closed)="displayFailureAlert = false">{{
                errorMessage }}
            </ngb-alert>
        </div>
        <app-header  id="create-msg-header" [specialHeaderInfo]="specialHeaderInfo" [headerDataLeft]="headerDataLeft" [headerDataRight]="null"
            [actions]="actions" (onBackClick)="onBackButtonClicked()" (onPreviewClick)="onPreviewClick($event)"
            (onActionClick)="handleActionClick($event)"></app-header>
    </div>

    <section id="create-message-details-container">
        <div class="design-factory-v2 card h-100">
            <div class="px-4">
                <form id="CreateMessageForm" [formGroup]="createMessageForm" novalidate>
                    <!-- Necessary Details section -->
                    <section class="card-body" style="padding: 10px 10px 10px;">
                        <div class="flex-container py-2">
                            <div class="label-value-list my-2">
                                <div class="create-message-item m-1">
                                    <span class="red-asterisk"
                                        *ngIf="createMessageDetails.MessageFlow === messageFlows.Proposal || createMessageDetails.MessageFlow === messageFlows.TurnDown">*</span><label
                                        i18n>To&nbsp;</label>
                                    <textarea type="text" id="toTextField" class="form-control" rows="1"
                                        [value]="createMessageDetails.CommonInfo.To" (input)="onToValueChange()"
                                        [ngClass]="(submitted && createMessageForm.controls.To.errors ? 'form-control is-invalid' : 'form-control')"
                                        [readonly]="isReadonlySelfAward" formControlName="To"></textarea>
                                </div>
                                <div class="create-message-item m-1">
                                    <label i18n>From&nbsp;</label>
                                    <textarea id="fromTextField" class="form-control" rows="1"
                                        readonly>{{createMessageDetails.CommonInfo.From}}</textarea>
                                </div>
                            </div>
                            <div class="label-value-list my-2"
                                *ngIf="createMessageDetails.MessageFlow === messageFlows.Proposal">
                                <div class="create-message-item m-1">
                                    <label i18n>CC&nbsp;</label>
                                    <input type="text" id="ccTextField" class="form-control"
                                        [value]="createMessageDetails.ProposalInfo.Cc" formControlName="Cc"
                                        [ngClass]="(submitted && createMessageForm.controls.Cc.errors ? 'form-control is-invalid' : 'form-control')" />
                                </div>
                                <!-- empty div tag to align items properly -->
                                <div class="create-message-item m-1"></div>
                            </div>
                        </div>
                    </section>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.Proposal); 
                                    then CreateMessageForProposal">
                    </ng-container>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.Redirect); 
                                    then RedirectRfp">
                    </ng-container>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.Reassign); 
                                    then ReassignRfp">
                    </ng-container>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.NewRfp); 
                                    then NewRfp">
                    </ng-container>
                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.TurnDown);
                                    then TurnDown">

                    </ng-container>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.UpdateRfp); 
                                    then UpdateRfp">
                    </ng-container>

                    <ng-container *ngIf="(createMessageDetails.MessageFlow === messageFlows.SelfAward); 
                                    then SelfAward">
                    </ng-container>


                    <ng-template #CreateMessageForProposal>
                        <!-- Attachments and options section -->
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="row">
                                <div class="col">
                                    <button i18n type="button" class="btn btn-outline-primary mb-2 mb-md-0 me-2 mt-2"
                                        (click)="openModal('Proposal')"  id="grSelectResponse"><span class="fa-file-export"></span>Select
                                        Response</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ">
                                </div>
                                <div class="col-md-6 clear-comment">
                                    <button class="btn btn-comment-clear-icon" id="ClearComment"><span class="fa-times fa-solid"
                                            (click)="clearAllComments()"></span></button>
                                    <a class="df-link-more df-link-more-sm btn-comment-clear-link"
                                        (click)="clearAllComments()">
                                        <span class="df-link-more-text btn-comment-clear-text" i18n>Clear
                                            Comment</span>
                                    </a>
                                </div>
                            </div>
                            <app-rich-text id='rich-text-editor-createmessage' [currentValue]="richTextValue"
                                (richTextEditorChangeEvent)="onChangesOnRTE($event)"></app-rich-text>
                            <p i18n class="errorMessage" id="grCommentErrorMessage" *ngIf="isRTECharLimitReached">
                                You have reached the
                                maximum character limit for Proposal Comments.</p>
                        </section>
                    </ng-template>

                    <ng-template #RedirectRfp>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="flex-container comments-container">
                                <label i18n>Comments</label>
                                <textarea class="text-area-control form-control comments-control" rows="10"
                                    formControlName="Comments"></textarea>
                            </div>
                        </section>
                    </ng-template>

                    <ng-template #ReassignRfp>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="row">
                                <div class="col-md-6 ">
                                </div>
                                <div class="col-md-6 clear-comment">
                                    <button class="btn btn-comment-clear-icon" id="ClearComment"><span class="fa-times fa-solid"
                                            (click)="clearAllComments()"></span></button>
                                    <a class="df-link-more df-link-more-sm btn-comment-clear-link"
                                        (click)="clearAllComments()">
                                        <span class="df-link-more-text btn-comment-clear-text" i18n>Clear
                                            Comment</span>
                                    </a>
                                </div>
                            </div>
                            <app-rich-text id='rich-text-editor-createmessage' [currentValue]="richTextValue"
                                (richTextEditorChangeEvent)="onChangesOnRTE($event)"></app-rich-text>
                            <p i18n class="errorMessage" id="grCommentErrorMessage" *ngIf="isRTECharLimitReached">
                                You have reached the
                                maximum character limit for Reassign RFP Comments.</p>
                        </section>
                    </ng-template>

                    <ng-template #NewRfp>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="flex-container comments-container">
                                <label i18n>Comments</label>
                                <textarea class="text-area-control form-control comments-control" rows="10"
                                    formControlName="Comments"></textarea>
                            </div>
                        </section>
                    </ng-template>

                    <ng-template #TurnDown>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="label-value-list my-2">
                                <div class="create-message-item m-1">
                                    <label i18n>System Recipients&nbsp;</label>
                                    <textarea type="text" id="systemRecipientsTextField"
                                        class="form-control text-area-control" rows="1" readonly="true"
                                        [value]="createMessageDetails.TurnDownInfo.SystemRecipients"
                                        formControlName="SystemRecipients"></textarea>
                                </div>
                                <div class="create-message-item m-1">
                                    <span class="red-asterisk"
                                        *ngIf="createMessageDetails.MessageFlow === messageFlows.TurnDown">*</span><label
                                        i18n>Reason</label>
                                    <ng-select class="TurnDownReasons" label="SelectReason" [selectOnTab]="true"
                                        name="turnDownReason" [ngModelOptions]="{standalone: true}"
                                        [items]="turnDownReasons" (change)="onReasonSelected()"
                                        [(ngModel)]="turnDownSelectedReason" placeholder="Select Reason"
                                        [searchable]="false" [clearable]="false" id="aiReasons">
                                        <ng-option id="reason_{{i}}"
                                            *ngFor="let turnDownReason of turnDownReasons;let i = index"
                                            [value]="turnDownReason.reason">{{turnDownReason.reason}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <section class="card-body" style="padding: 10px 10px 10px;">
                                <div class="row">
                                    <div class="col">
                                        <button i18n type="button" class="btn btn-outline-primary mb-2 mb-md-0 me-2 mt-2"
                                            (click)="openModal('TurnDown')"  id="grSelectResponse"><span class="fa-file-export"></span>Select
                                            Response</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 ">
                                    </div>
                                    <div class="col-md-6 clear-comment">
                                        <button class="btn btn-comment-clear-icon" id="ClearComment"><span class="fa-times fa-solid"
                                                (click)="clearAllComments()"></span></button>
                                        <a class="df-link-more df-link-more-sm btn-comment-clear-link"
                                            (click)="clearAllComments()">
                                            <span class="df-link-more-text btn-comment-clear-text" i18n>Clear
                                                Comment</span>
                                        </a>
                                    </div>
                                </div>
                                <app-rich-text id='rich-text-editor-createmessage' [currentValue]="richTextValue"
                                    (richTextEditorChangeEvent)="onChangesOnRTE($event)"></app-rich-text>
                                <p i18n class="errorMessage" id="grCommentErrorMessage" *ngIf="isRTECharLimitReached">
                                    You have reached the
                                    maximum character limit for Turn Down Comments.</p>
                            </section>
                        </section>
                    </ng-template>

                    <ng-template #UpdateRfp>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <div class="flex-container comments-container">
                                <label i18n>Comments</label>
                                <textarea class="text-area-control form-control comments-control" rows="10"
                                    formControlName="Comments"></textarea>
                            </div>
                        </section>
                    </ng-template>

                    <ng-template #SelfAward>
                        <section class="card-body" style="padding: 10px 10px 10px;">
                            <span class="red-asterisk"
                                *ngIf="createMessageDetails.MessageFlow === messageFlows.SelfAward">*</span><span
                                class="create-message-item" id="radio-group-1" i18n>Start Date:</span>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="SelfAwardDate" checked
                                    [value]="createMessageDetails.SelfAwardInfo.Date" id="DefaultSelfAwardDate"
                                    (change)="onDateTypeChange($event)" />
                                <label class="form-check-label">
                                    {{this.defaultStartDate}}
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input radioBtnMarginTop" #custSelfAwardDate name="SelfAwardDate"
                                    id="CustomSelfAwardDateId" (change)="onDateTypeChange($event)" />
                                    
                                    <div class="input-group text" id="datepicker-popup"
                                    [dfCloseInputDatePicker]="dp">
                                    <input #dpin id="CustomSelfAwardDate" class="form-control" [(ngModel)]="minCustomDate"
                                        (ngModelChange)="onCustomSelfAwardDateSelect($event)" aria-label="Date" name="dp"
                                        ngbDatepicker #dp="ngbDatepicker" [displayMonths]="1" [navigation]="'arrows'"
                                        [ngModelOptions]="{standalone: true}" [outsideDays]="'collapsed'" [showWeekNumbers]="false" [dayTemplate]="dpdt"
                                        [footerTemplate]="footertemplate" (dateSelect)="dpin.focus()" [placeholder]="[placeholder]"
                                         (paste)="$event.preventDefault()"
                                        autocomplete="off" (keydown)="disableDate()"
                                        [minDate]="minCustomDate" [maxDate]="'9999-12-31'" />
                                    <div class="input-group-append">
                                        <button class="input-group-text fa-calendar" id="toggleDatePicker" (click)="dp.toggle()"
                                            type="button" [disabled]="!custSelfAwardDate.checked"><span class="visually-hidden">Toggle
                                                datepicker</span></button>
                                    </div>

                                    <ng-template #footertemplate>
                                        <div class="text-center">
                                            <button class="btn bg-transparent" id="clearDateBtn" (click)="clearDate(dpin)" i18n>
                                                Clear
                                            </button>
                                        </div>
                                    </ng-template>
                                    <ng-template #dpdt let-date let-disabled="disabled" let-focused="focused" let-selected="selected">
                                        <div class="df-datepicker-date" [class.df-datepicker-date-disabled]="disabled"
                                            [class.df-datepicker-date-focused]="focused" [class.df-datepicker-date-selected]="selected" role="link">
                                            {{date.day}}
                                        </div>
                                    </ng-template>
                                </div>

                            </div>
                            <div>
                            </div>
                            <section class="card-body" style="padding: 10px 10px 10px;">
                                <div class="row">
                                    <div class="col-md-6 ">
                                    </div>
                                    <div class="col-md-6 clear-comment">
                                        <button class="btn btn-comment-clear-icon" id="ClearComment"><span class="fa-times fa-solid"
                                                (click)="clearAllComments()"></span></button>
                                        <a class="df-link-more df-link-more-sm btn-comment-clear-link"
                                            (click)="clearAllComments()">
                                            <span class="df-link-more-text btn-comment-clear-text" i18n>Clear
                                                Comment</span>
                                        </a>
                                    </div>
                                </div>
                                <app-rich-text id='rich-text-editor-createmessage' [currentValue]="richTextValue"
                                    (richTextEditorChangeEvent)="onChangesOnRTE($event)"></app-rich-text>
                                <p i18n class="errorMessage" id="grCommentErrorMessage" *ngIf="isRTECharLimitReached">
                                    You have reached the
                                    maximum character limit for SelfAward RFP Comments.</p>
                            </section>
                        </section>
                    </ng-template>

                    <!-- Attachment and Send/Preview buttons -->
                    <section class="card-body" style="padding: 10px 10px 10px;">
                        <div class="attachment-container mt-2">
                            <div class="attachment-list" class="col-12">
                                <div class="col-lg-12 create-message-item">
                                    <label i18n>File1:</label>
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="file" id="attachment1"
                                                class="file-upload form-control clsAttachment1" #attachment1
                                                accept={{allowedFileTypes}} (change)="updateFile($event, '1')"
                                                [disabled]="(isFileUploading1 || uploadSucess1) ? true: false" />
                                        </div>
                                        <div class="col-8" style="margin-top: 5px;padding-left: 5px;"
                                            *ngIf="isFileUploading1">
                                            <div class="spinner-border spinner-border-sm text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px;padding-left: 5px;"
                                            *ngIf="uploadSucess1">
                                            <i class="fa-check-circle pr-1" style="color:green;"></i><span
                                                class="success-indicator" i18n>The file has been uploaded
                                                successfully.</span>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px;padding-left: 5px;">
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadFaliure1"></i><span class="failure-indicator"
                                                *ngIf="uploadFaliure1" i18n>Possible malicious content detected. The
                                                attachment will not be saved.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadNotValidate1"></i><span class="failure-indicator"
                                                *ngIf="uploadNotValidate1" i18n>Specified file cannot be found.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadCommitted1"></i><span class="failure-indicator"
                                                *ngIf="uploadCommitted1" i18n>File with provided Id is already present
                                                in Meetingbroker.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 create-message-item">
                                    <label i18n>File2:</label>
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="file" id="attachment2"
                                                class="file-upload form-control clsAttachment2" #attachment2
                                                accept={{allowedFileTypes}} (change)="updateFile($event, '2')"
                                                [disabled]="(isFileUploading2 || uploadSucess2) ? true: false" />
                                        </div>
                                        <div class="col-8" style="margin-top: 5px;padding-left: 5px;"
                                            *ngIf="isFileUploading2">
                                            <div class="spinner-border spinner-border-sm text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px;padding-left: 5px;"
                                            *ngIf="uploadSucess2">
                                            <i class="fa-check-circle pr-1" style="color:green;"></i><span
                                                class="success-indicator" i18n>The file has been uploaded
                                                successfully.</span>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px;padding-left: 5px;">
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadFaliure2"></i><span class="failure-indicator"
                                                *ngIf="uploadFaliure2" i18n>Possible malicious content detected. The
                                                attachment will not be saved.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadNotValidate2"></i><span class="failure-indicator"
                                                *ngIf="uploadNotValidate2" i18n>Specified file cannot be found.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadCommitted2"></i><span class="failure-indicator"
                                                *ngIf="uploadCommitted2" i18n>File with provided Id is already present
                                                in Meetingbroker.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 create-message-item">
                                    <label i18n>File3:</label>
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="file" id="attachment3"
                                                class="file-upload form-control clsAttachment3" #attachment3
                                                accept={{allowedFileTypes}} (change)="updateFile($event, '3')"
                                                [disabled]="(isFileUploading3 || uploadSucess3) ? true: false" />
                                        </div>
                                        <div class="col-8" style="margin-top: 5px; padding-left: 5px;"
                                            *ngIf="isFileUploading3">
                                            <div class="spinner-border spinner-border-sm text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px; padding-left: 5px;"
                                            *ngIf="uploadSucess3">
                                            <i class="fa-check-circle pr-1" style="color:green;"></i><span
                                                class="success-indicator" i18n>The file has been uploaded
                                                successfully.</span>
                                        </div>
                                        <div class="col-8" style="margin-top: 7px; padding-left: 5px;">
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadFaliure3"></i><span class="failure-indicator"
                                                *ngIf="uploadFaliure3" i18n>Possible malicious content detected. The
                                                attachment will not be saved.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadNotValidate3"></i><span class="failure-indicator"
                                                *ngIf="uploadNotValidate3" i18n>Specified file cannot be found.</span>
                                            <i class="fa-times-circle pr-1" style="color:red;"
                                                *ngIf="uploadCommitted3"></i><span class="failure-indicator"
                                                *ngIf="uploadCommitted3" i18n>File with provided Id is already present
                                                in Meetingbroker.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- empty div tag to align attachments properly -->
                            <div class="attachment-list">
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    </section>
</div>
