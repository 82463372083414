import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { dateTimeFormatOptionsForDateOnly } from 'projects/shared/pagination/src/app/models/dateTimeFormatOptions';
import moment from 'moment';
import { LocaleService } from 'src/app/services/locale.service';

//If we use current placeholder like fr- , then control gets distorted
//These weekdays and month data is used in ngbdatepicker to support for different locales.
//This data should be considered as placeholder data to make the ngbdatepicker working in different locales. 
//This data should be reviewed and updated when actual localization change is planned for enriched ui

const I18N_VALUES = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  },
  'de': {
    weekdays: ['Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.', 'So.'],
    months: ['Jan.', 'Feb.', 'März', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'],
  },
  'es':{
    weekdays:[ 'Lun.', 'Mar.', 'Mié.', 'Jue.', 'Vie.', 'Sáb.', 'Dom.'],
    months: ['enero', 'feb', 'mar', 'abr', 'mayo', 'Jun', 'Jul', 'agosto', 'sept', 'oct', 'nov', 'dic.']
  },
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  },
};


@Injectable()
export class LocalizedDateFormatter extends NgbDatepickerI18n {

  localLocale: string;
  constructor(private readonly localeService: LocaleService) {
    super();
    if(this.localeService.locale)
    {
      this.localLocale = this.localeService.locale;
    }
    else
    {
      this.localLocale = this.localeService.defaultLocale;
    }   
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this.localLocale].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this.localLocale].months[month - 1];   
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  format(date: NgbDateStruct): string {
    let modelDate;
    if (date !== null) {
      modelDate = date.year + "-" + date.month + "-" + date.day;
    }
      return date ? Intl.DateTimeFormat(this.localLocale, dateTimeFormatOptionsForDateOnly).format(moment(modelDate,"YYYY-MM-DD").utc().toDate()).toString() : null;
  }
}
