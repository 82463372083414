import { Component, OnInit, QueryList, ViewChildren, ViewContainerRef, } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { LocaleService } from "src/app/services/locale.service";

@Component({
  selector: 'app-grid-time-dropdown',
  templateUrl: './grid-time-dropdown.component.html',
  styleUrl: './grid-time-dropdown.component.scss'
})
export class GridTimeDropdownComponent implements ICellEditorAngularComp {
  public inputDate: any;
  public params: any;
  allowedMinutes = [0,30]; // Example: Allow only 0 and 30 minutes
  @ViewChildren('input', { read: ViewContainerRef })

  public inputs: QueryList<any>;
  public fieldName: any;
  validTimes: string[] = [];
  // private focusedInput: number = 0;

  constructor(private localeService: LocaleService) {}

  getValidTime(date: Date): string {
    const hours = date.getHours();
    const minutes = this.allowedMinutes.find(min => min >= date.getMinutes()) || this.allowedMinutes[0];
    return `${this.pad(hours)}:${this.pad(minutes)}`;
  }

  generateValidTimes(): string[] {
    const times: string[] = [];
    for (let hour = 1; hour <= 12; hour++) {
      for (const minute of this.allowedMinutes) {
        times.push(`${this.pad(hour)}:${this.pad(minute)} AM`);
      }
    }
    for (let hour = 1; hour <= 12; hour++) {
      for (const minute of this.allowedMinutes) {
        times.push(`${this.pad(hour)}:${this.pad(minute)} PM`);
      }
    }
    return this.sortTime(times);
  }

  // arrange time from AM to PM.
  sortTime(times: string[]): string[] {
    let existingIndex = times.indexOf("12:30 AM");
    times[existingIndex] = times.pop();
    existingIndex = times.indexOf("12:00 AM");
    times[existingIndex] = times.pop();
    times.unshift("12:30 AM");
    times.unshift("12:00 AM");
    return times;
  }


  pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  agInit(params: any): void {
    this.validTimes = this.generateValidTimes();
    // Set initial value to a valid time
    this.inputDate = this.validTimes[0];
    this.params = params;
    this.fieldName = this.params.colDef.field;
    this.inputDate = this.formatTime(params.value);
    
  }

  formatTime(timeString) {
    const [hourString, minute] = timeString.split(" ")[0].split(":");
    let hour = +hourString % 24;
    let dayOrNight = (hour < 12 ? "AM" : "PM");
    hour = (hour % 12 || 12);
    let formatHour = hour === 0 || hour < 10? `0${hour}`:hour;
    return `${formatHour}:${minute} ${dayOrNight}`;
  }

  ngAfterViewInit() {
    if (this.fieldName != "checkBox") {
      this.focusOnInputNextTick(this.inputs.first);
    }
  }

  private focusOnInputNextTick(input: ViewContainerRef) {
    window.setTimeout(() => {
      input.element.nativeElement.focus();
    }, 0);
  }

  getValue() {
    return this.formatTimeTo24Hour(this.inputDate);
  }
  formatTimeTo24Hour(timeString: string): string {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours.padStart(2, "0")}:${minutes}`;
  }
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  selectedTime(event: any): void {
    const inputElement = event.target as HTMLSelectElement;
    const timeValue = inputElement.value;

    // Check if the entered time is valid
    if (timeValue && !this.validTimes.includes(timeValue)) {
      event.preventDefault();
      inputElement.value = this.validTimes[0]; // Reset to a valid time
    }
  }
}
