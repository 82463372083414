import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable, Subject } from "rxjs";
import { AttachmentResponse,  MessageDetailsResponseModel } from "../models/message-details.model";
import { MessageDetailsServiceUrlPath } from "../../../../../src/app/utils/constants";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MessageDetailsService {
  private messageServiceUrl: string;
  public messages$ = new Subject<MessageDetailsResponseModel>();
  public isMessageDataLoading$ = new Subject<boolean>();
  public errors$ = new Subject<any>();

  constructor(private http: HttpClient, private router: Router) {
    this.messageServiceUrl = `${sessionStorage.getItem('MessageServiceBaseUrl')}${MessageDetailsServiceUrlPath}`;
  }

  getMessageDetails(messageId: Guid) {
    this.isMessageDataLoading$.next(true);
    this.http
      .get<MessageDetailsResponseModel>(
        `${this.messageServiceUrl}/${messageId}`
      )
      .subscribe(
        (x) => {
          if (x != null) {
            this.messages$.next(x);
          }
          this.isMessageDataLoading$.next(false);
        },
        (error: HttpErrorResponse) => {
          this.isMessageDataLoading$.next(false);
          switch (error.status) {
            case 403:
              this.errors$.next({
                translateKey: "common-messages-txt-forbidden",
                defaultText:
                  "Access Forbidden.",
              });
              this.redirectToLoginPage();
              break;
            case 401:
              this.redirectToLoginPage();
              break;
            default:
              this.redirectToErrorPage();
          }
        }
      );
  }
  redirectToErrorPage() {
    this.router.navigate(["/error"]);
  }

  redirectToLoginPage() {
    this.router.navigate(["/login"]);
  }

  public getAttachmentDetails(messageId: string, attachmentId: string): Observable<AttachmentResponse> {
    return this.http.get<AttachmentResponse>(`${this.messageServiceUrl}/${messageId}/attachments/${attachmentId}`);
  }
}
