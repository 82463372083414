import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, } from '@angular/core';
import { NgbCollapse, NgbModule, NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
// import { AppHeaderModule, AppSideNavModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from '../app.component';
import { LoginComponent } from '../components/login/login.component';
import { OidcLoginComponent } from '../components/oidc-login/oidc-login.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ErrorHandlerModule } from './error-handler.module';
import { AuthInterceptor } from '../interceptors/auth-interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './shared.module';
import { DfModalService, DfSelectModule, DfSideNavCollapseModule, DfSideNavModule } from '@design-factory/design-factory';
import { PageUnderProgressComponent } from '../components/page-under-progress/page-under-progress.component';
import { SubscriptionListComponent } from '../components/subscription-list/subscription-list.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NavbarHeaderComponent } from '../components/navbar-header/navbar-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommentContainerComponent } from 'projects/rfp-list/src/app/proposal-details/comment-container/comment-container.component';
import { LocationCommentsContainerComponent } from 'projects/rfp-list/src/app/proposal-details/location-comments-container/location-comments-container.component';
import { SubscriptionCommentsContainerComponent } from 'projects/rfp-list/src/app/proposal-details/subscription-comments-container/subscription-comments-container.component';
import { ContentTooltipCellrendererComponent } from 'projects/rfp-list/src/app/proposal-details/content-tooltip-cellrenderer/content-tooltip-cellrenderer.component';


@NgModule({
    declarations: [
        AppComponent,
        SubscriptionListComponent,
        LoginComponent,
        OidcLoginComponent,
        PageNotFoundComponent,
        PageUnderProgressComponent,
        ErrorPageComponent,
        NavbarHeaderComponent,
        CommentContainerComponent,
        LocationCommentsContainerComponent,
        SubscriptionCommentsContainerComponent,
        ContentTooltipCellrendererComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports:
        [BrowserModule,
            AppRoutingModule,
            NgbModule,
            BrowserAnimationsModule,
            FormsModule,
            ReactiveFormsModule,
            ErrorHandlerModule,
            NgSelectModule,
            SharedModule,
            DfSideNavCollapseModule,
            NgbCollapse, AsyncPipe, DfSideNavModule,
            DfSelectModule,
            FroalaEditorModule.forRoot(),
            FroalaViewModule.forRoot(),
            CommonModule,
            AgGridModule,
            NgbNavModule,
            NgbPaginationModule
            //HttpClientInMemoryWebApiModule.forRoot(DataService, { passThruUnknownUrl: true })
        ], providers: [
            BnNgIdleService,
            [{
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
            },
            ],
            DfModalService,
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
